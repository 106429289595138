import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import "./Policies.css";

const PrivacyPolicyScreen = () => {
  return (
    <>
      <Header />
      <Container className="root">
        <div className="main">
          <h2
            style={{ width: "100%" }}
            className="h2_main text-start pb-4"
          >{`SHIPPING, RETURNS and WARRANTY `}</h2>
          <p className="para_main">
            <span style={{ textDecoration: "underline" }}>
              {" "}
              Our TRU Off Grid Policies for Free Shipping, Product Returns and
              Special Orders
            </span>
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>Free Shipping</span>
            <br />
            <br />
            Canada: Free Shipping Canada Wide on All Products under 110 lbs.
            Over 110lbs will be charged to client or cost shared. Please contact
            us to discuss shipping on larger, high value products. Jack Abrams
            905-299-7841.
            <br />
            <br />
            USA: Free Shipping to USA Customers on Lithium Batteries by Dakota
            Lithium and EPOCH Batteries only.
            <br />
            <br />
            Shipping Solar Panels: We do not ship Rigid Solar Panels due to
            Dimensions and Fragility. We do ship EcoFlow portable and foldable
            solar panels for free.
            <br />
            <br />
            Tracking: We provide customers with a Tracking Number on all orders
            and will often insure shipments which requires signature on
            delivery.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              Product Return Policy - For Reason other than a Manufacturers
              Defect.
            </span>
            <br />
            <br />
            We offer a 120 Day Return Policy for reason other than manufacturers
            defect. The product must be returned at the expense of the customer,
            in original packaging, in working order with all accessories and in
            new and perfect working condition. Products returned for any reason
            will be inspected and tested prior to refund.
            <br />
            <br />
            For products returned for any reason other than a manufacturer's
            defect a 5% Restocking Fee will be deducted from the refund amount.
            If the returned product is purchased by Paypal or Credit Card we
            will deduct 3% from the refund which is our fee for transactions
            through Paypal and Credit Card.
            <br />
            <br />
            In summary, products returned for any reason other than due to a
            manufacturers defect will result in an 8% deduction from the refund.
            Deduction from refund may be higher than 8% if there is any damage
            or the product is not in new and perfect condition.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              Product Return Policy - Manufacturers Defect Warranty and
              Performance Issues
            </span>
            <br />
            <br />
            For manufacturer defects under warranty there no cost to a customer
            for a return. A product can be returned for replacement at any time
            based on manufacturers warranty specifications and process which we
            will manage on behalf of our customers. Our involvement accelerates
            warranty replacement.
            <br />
            <br />
            As an example for EcoFlow and Dakota Lithium products, we provide
            customer invoice, images or a video and explanation of the issue to
            their respective technical service departments. Generally a product
            is replaced within a two week period depending on inventory. We also
            can often provide immediate replacement as a stocking distributor
            for most products including EcoFlow and Dakota Lithium.
            <br />
            <br />
            Larger solar inverter and larger battery systems may require in
            depth diagnosis (remote or on site) which we handle on a
            case-by-case basis. Regardless, we manage the warranty process on
            your behalf to expedite communication, replacement and customer
            satisfaction.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              Warranty Overview by Supplier/Partner
            </span>
            <br />
            <br />
            Dakota Lithium: Full 11 Year Warranty (Not Pro-Rated). The best
            warranty in the business. We manage warranty locally for our clients
            with replacement batteries. While warranty issues are rare, Dakota
            Lithium is excellent to work with as a dealer for our customers.
            Warranty does not cover damage or misuse. Ask for specific warranty
            exclusions.
            <br />
            <br />
            EcoFlow: Full 5 Year Replacement Warranty all hardware and
            software/firmware related issues. (Not ProRated). We manage warranty
            issues on behalf of our clients directly with EcoFlow. We are their
            largest Canadian dealer and stock product. Warranty does not cover
            damage or misuse.
            <br />
            <br />
            EG4: 10 Year Parts or Full Product Replacement Warranty on all
            Batteries and Inverters. 3 Years on Accessories.
            <br />
            <br />
            EP Cube (Canadian Solar): 10 Year Parts or Full Product Replacement
            Warranty on all Batteries and Inverters - Gateway Inverter. 3 Years
            on Accessories
            <br />
            <br />
            Solar Panels: Differs by manufacturer. Thornova offers 15 Years
            Product Quality Warranty And 25 Year Linear Performance Warranty. We
            are experienced installers and can ensure that you are solar panel
            investment and warranty is protected with proper installation.
            <br />
            <br />
            EPOCH Batteries: 11 Year Limited Warranty (Not Pro-Rated). We manage
            warranty locally for our clients with replacement batteries and
            ensure rapid response from EPOCH. Warranty does not cover damage or
            misuse. Ask for specific warranty exclusions.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              Special Order Products and Back Orders
            </span>
            <br />
            <br />
            The vast majority of the products we market, sell, support and
            install are readily available. However, if out of stock a product is
            usually marked out of stock on our website and Google Merchant. From
            time to time a product will back order with our suppliers. When a
            back order occurs we will communicate the availability of that
            product to our customer. TRU Off Grid has 21 business days to
            provide a back ordered product or the customer will be refunded at
            the full amount. This policy applies to products under $3,000.
            Should you be interested in a product over $3,000 please call Jack
            Abrams at 905 299 7841 discuss availability and any technical
            questions you may have.
            <br />
            <br />
            Should you purchase a product that is marked out of stock and want
            your refund within the 21 day period, a 5% restocking fee will apply
            as well as the 3% PayPal or credit card transaction cost.
            <br />
            <br />
            Please contact Jack Abrams at 905-299-7841 with any questions at all
            on product technical specifications, installation, certifications,
            or warranty.
            <br />
            <br />
            Thank you for your interest in support! <br /> TRU Off Grid <br />
            <a href="mailto:sales@truoffgrid.com">sales@truoffgrid.com</a>
          </p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default PrivacyPolicyScreen;
