import React from "react";
import styles from "./CallToAction.module.css";
import { Container, Row } from "react-bootstrap";

const CallToAction = ({ cta2, cta3 }) => {
  return (
    <>
      {!cta2 && !cta3 && (
        <div className={styles.main}>
          <div className={styles.innerCont}>
            <Container>
              <div className={styles.contentCont}>
                <div className={styles.cta}>
                  <img alt="" src="/images/cta.png" />
                  <div className={styles.content}>
                    <h1 className="h1_main text-center pb-2">
                      OUR TRUSTED PARTNERS
                    </h1>
                    <a
                      style={{ textDecoration: "none" }}
                      href="tel:+19052997841"
                    >
                      <button className="button_main">Give Us a Ring</button>
                    </a>
                  </div>
                </div>
                <div className={styles.iconsCont}>
                  <img alt="" src="/images/cta1.png" />
                  <img alt="" src="/images/cta2.png" />
                  <img alt="" src="/images/cta3.png" />
                  <img alt="" src="/images/cta4.png" />
                  <img
                    alt=""
                    style={{ width: "80px" }}
                    src="/images/cta5.jpg"
                  />
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
      {cta2 && (
        <div className={styles.main2}>
          <Container>
            <div className={styles.contentCont2}>
              <h2 className="h2_main">Call Us to Discuss Your Application</h2>
              <h1 className="heading_capital">
                Please call{" "}
                <span style={{ textDecoration: "underline" }}>
                  905 299 7841
                </span>{" "}
              </h1>
              <a href="tel: 905-299-7841">
                <button className="button_main">905-299-7841</button>
              </a>
            </div>
          </Container>
        </div>
      )}
      {cta3 && (
        <div className={styles.main2}>
          <Container>
            <div className={styles.contentCont2}>
              <h2 className="h2_main">OUR COMMERCIAL PROJECTS</h2>
              <h1 className="heading_capital">
                Please call{" "}
                <span style={{ textDecoration: "underline" }}>
                  905 299 7841
                </span>{" "}
                <br />
              </h1>
              {/* <p className="para_main text-center ps-5 pe-5">
                Solar System Installation, Solar Panel Installation, Ecoflow
                System Installation
              </p> */}
              <a href="tel: 905-299-7841">
                <button className="button_main">905-299-7841</button>
              </a>
            </div>
          </Container>
        </div>
      )}
    </>
  );
};

export default CallToAction;
