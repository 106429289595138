import React from "react";
import Footer from "../../components/Footer_/Footer";
import AboutSec from "../../components/AboutSec_/AboutSec";
import CommonHero from "../../components/CommonHero_/CommonHero";
import Services from "../../components/Services_/Services";
import CallToAction from "../../components/CallToAction/CallToAction";
import { Helmet } from "react-helmet";

const Commercial = () => {
  return (
    <>
      <Helmet>
        <title>
          TRU Off Grid | Custom Solar & Lithium Solutions for Businesses
        </title>
        <meta name="keywords" content="Batteries in Ontario" />
        <link rel="canonical" href="https://www.truoffgrid.com/" />
        <meta
          name="description"
          content="TRU Off Grid specializes in advanced solar and lithium battery kits for engineering, trucking, and Ontario Townships. Contact us for sustainable power solutions tailored to your needs."
        />
      </Helmet>
      <CommonHero
        image={"/images/hero-commercial.jpg"}
        heading="TRU Off Grid Commercial"
      />
      <AboutSec
        heading={"COMMERCIAL PROJECTS"}
        text={`We are experienced at providing unique LFP Battery, Solar Systems and Field Based Solar and Battery Power Solutions for a variety of industries including:
<br/><br/>
• Asset Tracking Power Systems for Disaster Recovery<br/>
• Ground Transport Batteries for Airline Industry<br/>
• Forklift Batteries<br/>
• Field Engineering and Field Testing Power Solutions<br/>
• Postal Courier <br/>
• Conveyor Belts<br/>
• Commercial Scales<br/>
• Portable LFP Battery Solar <br/>
• Mining<br/>
• Commercial Solar and Remote Solar - Pole Mount Systems.<br/>
• Self Heating Battery Solutions for Extreme Climate Scenarios<br/><br/>
Please contact Jack Abrams at <a href="tel:+19052997841">905 299 7841</a> or email <a href="mailto:sales@truoffgrid.com">sales@truoffgrid.com</a> to discuss your unique commercial project.`}
        img={"/images/commercial1.png"}
        cta={true}
      />
      <CallToAction cta3={true} />
      <div style={{ padding: "80px 0px 220px 0px" }}></div>
      <Footer newsletter={true} />
    </>
  );
};

export default Commercial;
