import React, { useState } from "react";
import Footer from "../../components/Footer_/Footer";
import AboutSec from "../../components/AboutSec_/AboutSec";
import CommonHero from "../../components/CommonHero_/CommonHero";
import CallToAction from "../../components/CallToAction/CallToAction";
import { Helmet } from "react-helmet";
import { Col, Container, Row, Spinner } from "react-bootstrap";
import styles from "./ContactScreen.module.css";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";

const SolarAndWindPower = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNum, setPhoneNum] = useState("");
  const [message, setMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const history=useNavigate()

  const formSubmitHandler = (event) => {
    event.preventDefault();
    setLoading(true);
    axios
      .post("/send-contact-form", {
        name,
        email,
        phoneNumber: phoneNum,
        message,
      })
      .then((response) => {
        if (response.data.msg === "success") {
          setError(false);
          setSuccess("Form Submitted Successfully!");
          setLoading(false);
          setEmail("");
          history('/thank-you')
        } else if (response.data.msg === "fail") {
          setSuccess(false);
          setError("Email failed to send, try again by reloading the page.");
          setLoading(false);
          setEmail("");
        }
      });
  };
  return (
    <>
      <Helmet>
        <title>Solar Installation Solutions | TRU Off Grid</title>
        <meta name="keywords" content="Batteries in Ontario" />
        <link rel="canonical" href="https://www.truoffgrid.com/" />
        <meta
          name="description"
          content="Discover TRU Off Grid's cutting-edge solar and wind energy solutions. From mobile to commercial applications, we specialize in custom solar and lithium energy systems."
        />
      </Helmet>
      <CommonHero
        image={"/images/hero-solarwindpower.jpg"}
        heading="Solar Installation"
      />
      <AboutSec
        heading={"Solar Energy"}
        subHeading={"Put the Sun to Work"}
        text={`At TRU Off Grid, we specialize in delivering cutting- edge Mobile, Off-Grid, Residential, and Commercial Solar Solutions as well as rugged Power Backup systems. Our expertise lies in supplying, installing, and servicing pre-designed Solar Generators and Power Kits crafted by reputable brands such as Ecoflow, EG4, and Canadian Solar. For those seeking a tailored approach, we can design Custom Solar and Lithium Energy solutions to meet your unique requirements! Contact us today and discuss how our solar solutions can empower your energy needs!`}
        img={"/images/solarwindpower1.png"}
        cta={true}
      />
      <CallToAction cta2={true} />
      <AboutSec
        heading={"Solar Installation"}
        subHeading={"Install with the TRU Team "}
        text={`TRU Off Grid provides a complete vertically integrated process, ensuring end-to-end control and quality throughout your entire Solar project. We assist with the initial system design based on energy usage, roof layouts, supply of materials, obtaining building permits & hydro approval (for Net-Metering), additional consulting and of course, the installation! As of today, the TRU team has installed, managed and repaired just over 650kW DC of Solar, and 200kW of battery backup storage. We are fully insured with $5m in liability, working at heights certified & over 5 years of installation experience.`}
        img={"/images/solarwindpower2.png"}
        reverse={true}
        cta={true}
      />
      <AboutSec
        heading={"How Solar Net-Metering Works"}
        subHeading={"Save Money with Solar"}
        text={`1. Solar Panels absorb sunlight, which then produces a DC electrical current. 2. The DC current produced travels through a Solar Inverter, which then converts DC power into accessible AC power for home electrical use or grid feedback .3. As normal, electricity is distributed throughout the household for any power source required. 4. If households are producing more Solar Power than electricity consumed, excess power is sent back to the grid for future credit use (cloudy days & winter months) 5. This results in your newly installed bi-directional meter spinning backwards, offsetting your Hydro Bill !`}
        img={"/images/solarwindpower3.webp"}
        cta={true}
      />
      <AboutSec
        heading={"EP Cube by Canadian Solar"}
        subHeading={"Hybrid Residential Energy Storage"}
        text={`Incorporate your Solar System with LiFePO4 Batteries with the Canadian Solar EP Cube, a residential hybrid energy storage system. A sleek, modern design that is extremely simple to install and cost effective. Stackable, expandable ecosystem from 9.9kW - 119.9kW storage and 45kW of continuous AC output! Offering automatic transfer switch feature to ensure your home's power is secured during an outage. Offset further costs with peak-shaving - the EP Cube can be commissioned to energize during TOU (Time-of-Use) mode, extremely beneficial for households on Ultra-Low Overnight Rates. Compatible with existing solar system AC inverters, fuel generators, level 2 EV chargers and more! Contact us today for your EP Cube Quote.`}
        img={"/images/solarwindpower4.png"}
        reverse={true}
        cta={true}
      />
      <AboutSec
        heading={"Canadian Greener Homes Loan (CGHL)"}
        subHeading={"Federal Loan"}
        text={`Natural Resources Canada is currently offering a $40,000, interest-free loan, with a 10-year repayment term, to support the cost of your Residential Solar System, known as the Canada Greener Homes Loan! The maximum eligible loan amount is calculated based on the retrofits selected in the application and the quotes for this work. Apply for loan today - <a target="no_blank" href="https://www.cghli.ca/home">https://www.cghli.ca/home</a>`}
        img={"/images/solarwindpower5.png"}
      />
      <div
        style={{ padding: "80px 0px 80px 0px" }}
        className="main text-center"
      >
        <Container>
          <Row className="gy-4 gx-5">
            <Col lg={6}>
              <div className={styles.contentCol}>
                <h2 className="h2_main pb-2">Contact us</h2>
                <h1 className="h1_main pb-4">
                  Need help with your solar project?{" "}
                  <span>Lets Talk</span>
                </h1>
                <Row>
                  <Col className="pt-3" lg={12}>
                    <a
                      style={{ textDecoration: "none" }}
                      href="tel: 905-299-7841"
                    >
                      <div className={styles.info}>
                        <img src="/images/phone.svg" />
                        <p className="para_main">905-299-7841</p>
                      </div>
                    </a>
                  </Col>
                  <Col className="pt-3" lg={12}>
                    <a
                      style={{ textDecoration: "none" }}
                      href="mailto: sales@truoffgrid.com"
                    >
                      <div className={styles.info}>
                        <img src="/images/mail.svg" />
                        <p className="para_main">sales@truoffgrid.com</p>
                      </div>
                    </a>
                  </Col>
                  <Col className="pt-3" lg={12}>
                    <a
                      style={{ textDecoration: "none" }}
                      target="no_blank"
                      href="https://www.google.com/maps/dir//186+Sarah+St,+Milton,+ON+L9T+1H2,+Canada/@43.5108146,-79.963851,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x267e0b12cb8a4ddf:0xae5c0834e6f5863a!2m2!1d-79.8814503!2d43.5108442?entry=ttu"
                    >
                      <div className={styles.info}>
                        <img src="/images/location.svg" />
                        <p className="para_main">
                          186 Sarah Street, Milton, ON (We See Customers "By
                          Appointment" Only. Please call Jack Abrams. We are not
                          a retail store. Thank you)
                        </p>
                      </div>
                    </a>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col lg={6}>
              <div className={styles.formCol}>
                <h1 className="h1_main pb-2">Let’s get in touch!</h1>
                <p className="para_main pb-5">
                  Lets discuss your project and find out what we can do
                </p>
                {loading ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: "black" }}
                      />
                    </div>
                  </div>
                ) : success ? (
                  <div className="row pb-4">
                    <div className="col-12">
                      <p className="para_main" style={{ color: "black" }}>
                        {success}
                      </p>
                    </div>
                  </div>
                ) : (
                  error && (
                    <div className="row pb-4">
                      <div className="col-12">
                        <p className="para_main" style={{ color: "black" }}>
                          {error}
                        </p>
                      </div>
                    </div>
                  )
                )}
                <form onSubmit={formSubmitHandler}>
                  <input
                    maxLength="12"
                    type="text"
                    placeholder="Your Full Name"
                    pattern=".{3,}"
                    required
                    value={name}
                    onChange={(event) => setName(event.target.value)}
                  />
                  <input
                    maxLength="36"
                    type="email"
                    placeholder="Email"
                    pattern=".{3,}"
                    required
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                  />
                  <input
                    maxLength="36"
                    type="text"
                    placeholder="Phone Number"
                    pattern=".{3,}"
                    required
                    value={phoneNum}
                    onChange={(event) => setPhoneNum(event.target.value)}
                  />
                  <input
                    style={{ resize: "none" }}
                    as="textarea"
                    maxLength="800"
                    type="text"
                    placeholder="Your Message"
                    required
                    value={message}
                    onChange={(event) => setMessage(event.target.value)}
                  />
                  <button className="button_main mt-3" type="submit">
                    Submit
                  </button>
                </form>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <div
        style={{ padding: "80px 0px 240px 0px" }}
        className="main text-center"
      >
        <Container>
          <h1 className="h1_main pb-5">Gallery</h1>
          <Row className="gy-4">
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery1.webp" />
            </Col>
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery2.webp" />
            </Col>
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery4.webp" />
            </Col>
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery5.webp" />
            </Col>
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery6.webp" />
            </Col>
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery7.webp" />
            </Col>
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery8.webp" />
            </Col>
            <Col lg={6}>
              <img style={{ width: "100%" }} src="/images/gallery3.webp" />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer newsletter={true} />
    </>
  );
};

export default SolarAndWindPower;
