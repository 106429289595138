import React from "react";
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer_/Footer";
import Header from "../../components/Header_/Header";
import "./Policies.css";

const ReturnPolicyScreen = () => {
  return (
    <>
      <Header />
      <Container className="root">
        <div className="main">
          <h2
            style={{ width: "100%" }}
            className="h2_main text-start pb-4"
          >{`TRU Off Grid Private Policy`}</h2>
          <p className="para_main">
            This privacy policy ("policy") will help you understand how TRU Off
            Grid uses and protects the data you provide to us when you purchase
            a product or register for our newsletter.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              What Customer Information Do We Collect When A Product is
              Purchased?
            </span>
            <br />
            <br />
            • Your name and address <br /> • Your email address and phone number{" "}
            <br /> <br /> We collect this information for Order Processing and
            Shipping Only. <br /> <br /> This information collected is protected
            through our web site platform : Heroku. The Heroku platform is
            designed to protect customer information from threats by applying
            security controls at every layer from physical to application and
            has the ability to rapidly deploy security updates without service
            interruption. All data is transmitted encrypted by a secure TLS
            connection. TLS (Transport Layer Security) is a standard internet
            protocol that encrypts email for privacy and secure delivery. TLS
            prevents unauthorized access of email and other information when
            it's in transit over internet connections.
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              What Customer Information We Do Not Collect When A Product is
              Purchased?
            </span>
            <br />
            <br />• We do not collect or store any Credit Card detail. Your
            Credit Card is processed through PayPal. Please see PayPal's Private
            Statement Here{" "}
            <a
              href="https://www.paypal.com/us/legalhub/paypal/privacy-full"
              target="no_blank"
            >
              PayPal Privacy Statement
            </a>
            <br />
            <br />
            <span style={{ textDecoration: "underline" }}>
              What Customer Information Do We Collect for the TRU Off Grid
              Newsletter?
            </span>
            <br />
            <br />
            • Your email address
            <br />
            <br />
            Should you wish to Unsubscribe from our Newsletter please contact {" "}
            <a href="mailto:sales@truoffgrid.com">sales@truoffgrid.com</a>
            <br />
            <br />
            If you have any questions about our Private Policy, please contact
            Jon Abrams at <a href="jonabrams@truoffgrid.com">jonabrams@truoffgrid.com</a>
            <br />
            <br />
            Thank you
            <br />
            TRU Off Grid
            <br />
            Milton, Ontario
          </p>
        </div>
      </Container>
      <Footer />
    </>
  );
};

export default ReturnPolicyScreen;
