import React from "react";
import CommonHero from "../../components/CommonHero_/CommonHero";
import Footer from "../../components/Footer_/Footer";
import Reviews from "../../components/Reviews_/Reviews";

const ThankYou = () => {
  return (
    <>
      <CommonHero shopHero={true} heading="Thank You! " />
      <Reviews />
      <Footer newsletter={true} />
    </>
  );
};

export default ThankYou;
