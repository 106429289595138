import React from "react";
import styles from "./AboutSec.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

const AboutSec = ({
  subHeading,
  heading,
  text,
  buttonText,
  buttonLink,
  reverse,
  backgroundImage,
  backgroundGradient,
  img,
  imgLink,
  cta,
  padding,
}) => {
  return (
    <div
      style={{
        backgroundImage: backgroundImage
          ? `url("/images/video_background.jpg")`
          : backgroundGradient && backgroundGradient,
        padding: padding && padding,
      }}
      className={styles.main}
    >
      <Container>
        <Row
          style={{ flexDirection: reverse && "row-reverse" }}
          className="gy-4 gx-lg-5"
        >
          <Col lg={6}>
            <div className={styles.imgCol}>
              {!imgLink && <img style={{maxHeight:"560px"}} alt="" src={img} />}
              {imgLink && (
                <a href={imgLink} target="no_blank">
                  <img alt="" src={img} />
                </a>
              )}
            </div>
          </Col>
          <Col lg={6}>
            <div className={styles.contentCol}>
              {subHeading && <h2 className="h2_main">{subHeading}</h2>}
              <h1 className="h1_main">{heading}</h1>
              <div
                dangerouslySetInnerHTML={{ __html: text }}
                className="para_main pt-2 pb-4"
              />
              {buttonText && (
                <Link to={buttonLink}>
                  <button className="button_main">{buttonText}</button>
                </Link>
              )}
              {cta && (
                <a href="tel: 905-299-7841">
                  <button className="button_main">
                    <img alt="" src="/images/icons/phone.svg" />
                    905-299-7841
                  </button>
                </a>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutSec;
