import React from "react";
import Footer from "../../components/Footer_/Footer";
import AboutSec from "../../components/AboutSec_/AboutSec";
import CommonHero from "../../components/CommonHero_/CommonHero";
import Services from "../../components/Services_/Services";
import { Helmet } from "react-helmet";

const BatteryTechnology = () => {
  return (
    <>
      <Helmet>
        <title>TRU Off Grid | Leading LiFePO4 Battery Solutions</title>
        <meta name="keywords" content="Batteries in Ontario" />
        <link rel="canonical" href="https://www.truoffgrid.com/" />
        <meta
          name="description"
          content="Discover TRU Off Grid's top-tier LiFePO4 battery solutions for Recreation, Off-Grid living, Residential use, and Solar Systems. Partnered with industry leaders for quality and reliability."
        />
      </Helmet>
      <CommonHero
        // image={"/images/hero-batterytechnology.jpg"}
        heading="BATTERY TECHNOLOGY"
      />
      <AboutSec
        heading={"LFP BATTERY SOLUTIONS - BE AWARE OF WHAT YOU BUY"}
        subHeading={"OUR PREFERRED BATTERY CHEMISTRY"}
        text={`TRU Off Grid offers our customers leading edge LFP battery solutions for Recreation (Water and Land), Off Grid, Home Back Up and Solar Energy Storage and Commercial Applications.
 <br/> <br/>
LFP or LiFePO4 is Lithium Iron Phosphate battery chemistry and offers many key advantages of ownership:
 <br/> <br/>
• SAFE and most stable lithium battery chemistry <br/>
• LIGHT weight. Half the weight of a Sealed Lead Acid Battery. <br/>
• FLAT voltage curve = 100% POWER to battery depletion <br/>
• FAST charging. Four times faster than SLA. <br/>
• LONG life span up to 6,000 Cycles <br/>
• REDUCED raw material input and all metal components are recyclable <br/> <br/>
Our LFP battery brands include Dakota Lithium, VOLTHIUM, EPOCH, ECOFLOW, EG4 and EP CUBE by Canadian Solar. All of theses companies ensure the highest standard of quality, safety testing and certification for our Canadian customers.`}
        img={"/images/battery1.png"}
        cta={true}
      />
      <AboutSec
        heading={"Be Aware Of What You Buy"}
        text={`There are many cheap, poor quality, foreign owned companies selling LFP batteries and these companies cut corners on quality assurance, components, safety, quality testing and certification. Price is important but not at the cost of reduced safety for your family. Please review this article by Transport Canada <a target="no_blank" href="https://tc.canada.ca/en/dangerous-goods/safety-advisories/lithium-batteries-be-aware-what-you-buy">Lithium batteries – Be aware of what you buy</a>`}
        img={"/images/lithium-battery.jpg"}
        reverse={true}
        cta={true}
      />
      <Services />
      <AboutSec
        heading={"LPF - Safety"}
        subHeading={"Safer Than You Think!"}
        text={`LiFePO4 or LFP batteries are the safest type of lithium battery on the market,
        they are highly stable and not prone to overheating. Even if they’re punctured, they
        won’t catch on fire. The cathode material in LiFePO4 is also non-hazardous material
        and therefore it poses no environmental or negative health hazards. LFP is free of rare
        and heavy metals, non-polluting, non-toxic, and in compliance with Canadian
        requirements.`}
        img={"/images/battery2.png"}
        reverse={true}
        cta={true}
      />
      <AboutSec
        heading={"LPF – Why Are They Great?"}
        subHeading={"Best In The Game"}
        text={`Energy capacity and low self-discharge rate: LiFePO4 or LFP batteries can be
        discharged up to 100% without being damaged, they offer a much higher energy capacity than
        lead-acid batteries, which can only be discharged up to 50% of their storage capacity. LFP
        batteries have a lower self-discharge rate compared to some other battery technologies. This
        means they hold their charge longer when not in use, which is handy for applications where 
        power is used intermittently. LFP batteries only lose an average of 3% charge per month of
        inactivity. It is still advisable to unplug them when the expected period of non-use is greater than
        3 months.`}
        img={"/images/battery3.png"}
        cta={true}
      />
      <AboutSec
        heading={"LFP – Additional Benefits"}
        subHeading={"It Doesn’t Get Better!"}
        text={`● Long battery life cycle: LFP batteries have a life-cycle of up to 6,000 cycles
        compared to 300 cycles for long-life lead acid batteries. Theoretically, an LFP
        battery could last for 15 years.<br/><br/>
        ● Performs well at temperatures: LFP batteries perform well at extremes of
        temperature with an operating range of -20C to +75C. Self Heating LFP batteries
        allow for charging in freezing temperatures.<br/><br/>
        ● Lightweight: A LiFePO4 battery weighs one third that of lead-acid batteries.`}
        img={"/images/battery4.png"}
        reverse={true}
        padding="80px 0px 220px 0px"
        cta={true}
      />
      <Footer newsletter={true} />
    </>
  );
};

export default BatteryTechnology;
