import React, { Fragment } from "react";
import Footer from "../../components/Footer_/Footer";
import BlogSec from "../../components/BlogSec_/BlogSec";
import Reviews from "../../components/Reviews_/Reviews";
import CategoriesRow from "../../components/CategoriesRow_/CategoriesRow";
import Hero from "../../components/Hero/Hero";
import AboutSec from "../../components/AboutSec_/AboutSec";
import Services from "../../components/Services_/Services";
import CallToAction from "../../components/CallToAction/CallToAction";
import { Helmet } from "react-helmet";

const HomeScreen = () => {
  return (
    <Fragment>
      <Helmet>
        <title>
          TRU Off Grid | Lithium Batteries & Solar Solutions in Milton, ON
        </title>
        <link rel="canonical" href="https://truoffgrid.com/" />
        <meta
          name="description"
          content="TRU Off Grid offers LiFePO4 batteries, EcoFlow kits, and solar systems. Family-owned in Milton, ON. Great prices, free shipping, and expert customer service."
        />
      </Helmet>
      <Hero />
      <CategoriesRow />
      <Services />
      <AboutSec
        heading={"ABOUT TRU OFF GRID"}
        subHeading={"Who We Are"}
        text={`We are a family business located in Milton, Ontario focused on lithium battery technology, home back up and solar energy solutions. We are relentless about offering our customers the best value for their dollar and providing the best solution for a use case. We partner only with reputable brands and companies who manufacture products that are rigorously tested and certified for performance and safety and offer the best warranty programs.
           <br/><br/> Please reach out to Jack Abrams, Owner and Co-Founder of TRU Off Grid at <a href="tel:+19052997841">905 299 7841</a> or <a href="mailto:sales@truoffgrid.com">sales@truoffgrid.com</a>
        `}
        buttonLink={"/about"}
        buttonText={"About us"}
        img={"/images/about.webp"}
      />
      <AboutSec
        heading={"We are Powering Life Anywhere"}
        text={`We supply innovative, sustainable and clean energy solutions. We offer a wide range
          of Lithium LiFePO4 battery, ECOFLOW Solar Kits, Solar Panels and Solar Systems to
          power your passions including Van, RV, Cottage, Home, Business or Commercial
          Applications. Please call Jack Abrams at <a href="tel:+19052997841">905 299 7841</a>`}
        buttonText={"Shop Now"}
        buttonLink={"/shop"}
        reverse={true}
        backgroundImage={true}
        img={"/images/video.png"}
        imgLink={"https://www.youtube.com/watch?v=htcdoUEYJF0"}
      />
      <AboutSec
        heading={"SOLAR INSTALLATION & BATTERY, INVERTER SYSTEM INSTALLATION"}
        text={`We are authorized installers for EcoFlow, EG4 and Canadian Solar EP Cube systems and are experienced Solar Installers for Grid Tie, Net Metre and Off Grid Solar Projects as well as Battery and Inverter systems for Home, Cottage and Commercial Power Back Up. We are fully insured, safety trained and partner with a master electrician for all home panel and circuit integraton projects. 
               <br/><br/>
               We have been successful at helping several customers with Solar Installation Grant and Loan Applications. 
               <br/><br/>
               Please contact Jack Abrams for Free Assessment and to discuss your project. `}
        buttonLink={"tel:+19052997841"}
        buttonText={"More Information"}
        img={"/images/urt.jpg"}
      />
      {/* <CategoriesSec />
      <MainCategoriesSec /> */}
      <CallToAction />
      <BlogSec />
      <Reviews />
      <Footer newsletter={true} />
    </Fragment>
  );
};

export default HomeScreen;
