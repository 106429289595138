import React from "react";
import Header from "../Header_/Header";
import styles from "./Hero.module.css";
import { Col, Container, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "./Hero.css";
import "swiper/css";
import "swiper/css/pagination";
import { Link } from "react-router-dom";

const Hero = () => {
  return (
    <div className={styles.main}>
      <Header />
      <div className={styles.inner}>
        <Container className="p-0">
          <div className={styles.swiperCont}>
            <Swiper
              spaceBetween={300}
              pagination={{
                clickable: true,
              }}
              breakpoints={{
                280: {
                  slidesPerView: 1,
                },
              }}
              modules={[Pagination]}
              className="hero_swiper"
            >
              <SwiperSlide>
                <Row lg={12} className="w-100 gy-5">
                  <Col lg={7}>
                    <div className={styles.contentCont}>
                      <h1 className="h1_main">
                        LITHIUM BATTERIES, ECOFLOW AND EG4 SOLUTIONS, HOME BACK
                        UP, ENERGY STORAGE, SOLAR PANELS & SOLAR SYSTEMS
                      </h1>
                      <p className="para_main">
                        Based in Milton, Ontario we provide a wide range of
                        Lithium Battery and Solar Solutions for Recreation,
                        Home, Cottage, Commercial and Off Grid applications. We
                        are an Authorized Dealer and Installer for EcoFlow, EG4
                        Electronics and EP Cube by Canadian Solar. We stock the
                        products on our site and offer Competitive Pricing and
                        <span
                          style={{
                            fontWeight: "900",
                            fontSize: "16px",
                            fontFamily: "Poppins",
                          }}
                        >
                          {" "}
                          Free Shipping on most products.
                        </span>
                        <br />
                        <br />
                        Please call Jack Abrams at{" "}
                        <a href="tel:9052997841">905 299 7841</a> with any
                        questions.
                      </p>
                      <Link style={{ textDecoration: "none" }} to="/shop">
                        <button className={`${styles.button1} button_main`}>
                          Shop Now{" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.988"
                            height="19.02"
                            viewBox="0 0 16.988 19.02"
                            fill="#fff"
                          >
                            <g id="bag" transform="translate(0.801 0.8)">
                              <path
                                id="Path_4188"
                                data-name="Path 4188"
                                d="M5.456,13.6A1.742,1.742,0,0,1,7.193,12H17.7a1.742,1.742,0,0,1,1.737,1.6l.7,8.71A1.742,1.742,0,0,1,18.4,24.194H6.493a1.742,1.742,0,0,1-1.736-1.881Z"
                                transform="translate(-4.751 -6.774)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                              <path
                                id="Path_4189"
                                data-name="Path 4189"
                                d="M18.968,10.839V6.484A3.484,3.484,0,0,0,15.484,3h0A3.484,3.484,0,0,0,12,6.484v4.355"
                                transform="translate(-7.791 -3)"
                                fill="none"
                                stroke="#fff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="1.6"
                              />
                            </g>
                          </svg>
                        </button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className={styles.imgCol}>
                      <img
                        alt=""
                        className={styles.backgroundImage}
                        src="/images/hero1.png"
                      />
                    </div>
                  </Col>
                </Row>
              </SwiperSlide>
              <SwiperSlide>
                <Row lg={12} className="w-100 gy-5">
                  <Col lg={7}>
                    <div className={styles.contentCont}>
                      <h1 className="h1_main">LITHIUM BATTERIES</h1>
                      <p className="para_main">
                        Based in Milton, Ontario we provide a wide range of
                        Lithium LiFePO4 Batteries, and we are an ECOFLOW
                        Authorized Distributor and Installer. We stock the
                        products that we sell, offer friendly customer service,
                        fantastic pricing and provide{" "}
                        <span
                          style={{
                            fontWeight: "900",
                            fontSize: "16px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Free Shipping Canada Wide
                        </span>
                        . Please call Jack Abrams at{" "}
                        <a href="tel:9052997841">905 299 7841</a> with any
                        questions.
                      </p>
                      <Link style={{ textDecoration: "none" }} to="/shop">
                        <button className={`${styles.button1} button_main`}>
                          Shop Now
                        </button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className={styles.imgCol}>
                      <img
                        alt=""
                        className={styles.backgroundImage}
                        src="/images/hero2.png"
                      />
                    </div>
                  </Col>
                </Row>
              </SwiperSlide>
              <SwiperSlide>
                <Row lg={12} className="w-100 gy-5">
                  <Col lg={7}>
                    <div className={styles.contentCont}>
                      <h1 className="h1_main">
                        ECOFLOW SOLUTIONS, ENERGY STORAGE
                      </h1>
                      <p className="para_main">
                        Based in Milton, Ontario we provide a wide range of
                        Lithium LiFePO4 Batteries, and we are an ECOFLOW
                        Authorized Distributor and Installer. We stock the
                        products that we sell, offer friendly customer service,
                        fantastic pricing and provide{" "}
                        <span
                          style={{
                            fontWeight: "900",
                            fontSize: "16px",
                            fontFamily: "Poppins",
                          }}
                        >
                          Free Shipping Canada Wide
                        </span>
                        . Please call Jack Abrams at{" "}
                        <a href="tel:9052997841">905 299 7841</a> with any
                        questions.
                      </p>
                      <Link style={{ textDecoration: "none" }} to="/shop">
                        <button className={`${styles.button1} button_main`}>
                          Shop Now
                        </button>
                      </Link>
                    </div>
                  </Col>
                  <Col lg={5}>
                    <div className={styles.imgCol}>
                      <img
                        alt=""
                        className={styles.backgroundImage}
                        src="/images/hero3.png"
                      />
                    </div>
                  </Col>
                </Row>
              </SwiperSlide>
            </Swiper>
          </div>
        </Container>
      </div>
    </div>
  );
};

export default Hero;
